<template>
	<div>
		<el-container style="height: calc(100vh - 135px); border: 1px solid #eee;width: 100%;">
			<el-aside width="350px" style="background-color: #fff;border: 1px solid #eee;">
				<el-input size="medium" placeholder="订单号、手机尾号" suffix-icon="el-icon-search" v-model="input2">
				</el-input>
				<div style="">
					<div style="position: relative;padding: 5px 20px;">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="顾客聊天" name="first"></el-tab-pane>
							<el-tab-pane label="系统通知" name="second"></el-tab-pane>
						</el-tabs>
						<div @click="yidu" style="position: absolute;right: 20px;top: 10px;cursor: pointer;"><i
								class="iconfont icon-admin-qingchu"></i></div>
					</div>
					<div style="padding: 5px 20px;">
						<el-tabs v-model="zhuangtai" @tab-click="handleClick">
							<el-tab-pane label="全部" name="all"></el-tab-pane>
							<el-tab-pane label="未读" name="weidu"></el-tab-pane>
							<el-tab-pane label="已读未回复" name="ydwhf"></el-tab-pane>
						</el-tabs>
					</div>
					<el-row>
						<div class="u-t-list">
							<el-empty description="暂无联系人" :image-size="100"></el-empty>
						</div>
					</el-row>
					<div style="width: 100%;">
						<el-select multiple style="width: 100%;" v-model="value" placeholder="请选择店铺">
							<el-option-group v-for="group in options" :key="group.label" :label="group.label">
								<el-option v-for="item in group.options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-option-group>
						</el-select>
					</div>
				</div>
			</el-aside>
			<el-container>
				<el-row style="width: 100%;">
					<div style="display: flex;align-items: center;justify-content: center;">
						<el-empty description="暂无数据"></el-empty>

					</div>
				</el-row>
			</el-container>
		</el-container>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeName: 'first',
				zhuangtai: 'all',
				input1: '',
				options: [{
					label: '饿了么外卖',
					options: [{
						value: 'Shanghai',
						label: '上海'
					}, {
						value: 'Beijing',
						label: '北京'
					}]
				}, {
					label: '饿了么零售',
					options: [{
						value: 'Chengdu',
						label: '成都'
					}, {
						value: 'Shenzhen',
						label: '深圳'
					}, {
						value: 'Guangzhou',
						label: '广州'
					}, {
						value: 'Dalian',
						label: '大连'
					}]
				}],
				value: ''
			}
		},
		methods: {
			yidu() {
				this.$confirm('确认将所有消息标记为已读？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$message({
						type: 'success',
						message: '成功!'
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			}
		}
	}
</script>

<style>
	.el-tabs__header {
		margin: 0 0 5px;
	}

	.u-t-list {
		width: 100%;
		overflow-y: scroll;
		height: calc(100vh - 325px);
	}
</style>
